<ng-template #addNewItem>
  <div
    class="new-item"
    *ngIf="!isSelectedCustom(searchTerm)"
    (click)="addCustomValue()"
  >
    {{
      (isMobile ? 'Tap' : 'Click') +
        ' here to add "' +
        searchTerm +
        '" as a new item'
    }}
  </div>
  <div
    class="already-added"
    *ngIf="isSelectedCustom(searchTerm)"
    class="new-item new-item-added"
  >
    {{ '"' + searchTerm + '" has already been added to the list.' }}
  </div>
</ng-template>

<div class="wrapper">
  <p class="text-xs mb-1">
    Name(s) of your purchasing Entity/LLC or Individual(s) Name
  </p>

  <div class="input-wrapper">
    <app-shared-input for="entitySearch" [value]="searchTerm">
      <input
        id="entitySearch"
        name="entitySearch"
        [(ngModel)]="searchTerm"
        (input)="onSearchChange()"
      />
    </app-shared-input>
  </div>

  <section *ngIf="!!loading" class="status-info">
    {{ statusMessage }}
  </section>

  <section *ngIf="!loading && searchTerm.length < 3" class="status-info">
    Enter at least 3 characters to search.
  </section>

  <ng-container *ngIf="!loading && searchTerm.length >= 3 && !options.length">
    <ng-container *ngTemplateOutlet="addNewItem"></ng-container>
  </ng-container>

  <section *ngIf="!loading && options.length" class="options">
    <ng-container *ngIf="!hasExactMatch">
      <ng-container *ngTemplateOutlet="addNewItem"></ng-container>
    </ng-container>
    <div
      *ngFor="let option of options; trackBy: trackByOptionKey"
      class="item"
      [ngClass]="{ selected: isSelected(option.key) }"
      (click)="toggleSelected(option)"
    >
      <ng-icon
        [name]="isSelected(option.key) ? 'featherCheckSquare' : 'featherSquare'"
      ></ng-icon>
      <div class="info">
        <div class="label" [innerHTML]="option.value"></div>
        <div *ngIf="option.desc" class="desc" [innerHTML]="option.desc"></div>
      </div>
    </div>
  </section>

  <section *ngIf="!loading && selected && selected.length" class="selected">
    <div class="title">Selected entities:</div>
    <div class="items">
      <div
        *ngFor="let item of selected; trackBy: trackByOptionKey"
        class="item"
      >
        <div class="label">{{ item.value }}</div>
        <div class="remove" (click)="removeSelected(item.key)">
          <ng-icon name="featherXCircle" size="13"></ng-icon>
        </div>
      </div>
    </div>
  </section>

  <div *ngIf="error" class="input-error-message">
    Please select at least one entity to proceed.
  </div>
</div>
