import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize, of } from 'rxjs';

import {
  PREFERENCES_OPTIONS,
  IPreferencesOptions,
} from '@core/constants/multi-selectors';
import { AccountPreferencesService } from '@core/services/account-preferences.service';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { setupAccountAction } from '@state/auth/auth.actions';
import {
  selectAuthUser,
  selectEntityId,
  selectAuthLoading,
} from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-account-setup-modal-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class AccountSetupFormComponent implements OnInit {
  public step = 1;
  protected loading = false;
  protected focused = '';
  public loadingSubmarkets = true;
  protected userFirstName: string | undefined;
  // if undefined show as loading
  protected skipStep1: boolean | undefined = false;

  public accountSetupForm: FormGroup = this.formBuilder.group({
    roles: new FormControl('', [Validators.required]),
    markets: new FormControl('', []), // Validators.required
    submarketIds: new FormControl('', [Validators.required]),
    investmentStyles: new FormControl('', []),
    rehabComfortabilities: new FormControl('', []),
    entities: new FormControl('', [Validators.required]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private accountPreferencesService: AccountPreferencesService
  ) {}

  protected options: IPreferencesOptions = {
    ...PREFERENCES_OPTIONS,
    submarkets: [],
  };

  ngOnInit(): void {
    this.store
      .select(selectAuthLoading)
      .pipe(untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
        if (loading) {
          this.accountSetupForm.disable();
        } else {
          this.accountSetupForm.enable();
        }
      });

    this.store
      .select(selectEntityId)
      .pipe(untilDestroyed(this))
      .subscribe((entityId) => {
        if (entityId) {
          this.accountSetupForm.controls['entities'].setValue([
            {
              id: entityId,
            },
          ]);
          this.skipStep1 = true;
          this.step = 2;
        } else {
          this.skipStep1 = false;
          this.step = 1;
        }
      });

    this.accountPreferencesService
      .listSubmarkets()
      .pipe(
        untilDestroyed(this),
        catchError((error) => {
          console.error('Error fetching submarkets:', error);
          return of({ listSubmarkets: [] });
        }),
        finalize(() => {
          this.loadingSubmarkets = false;
        })
      )
      .subscribe((res) => {
        this.options['submarkets'] = res.listSubmarkets.map((market) => ({
          key: market.id,
          value: market.displayName || market.name,
        }));
      });

    this.store
      .select(selectAuthUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        if (user) {
          this.userFirstName = user.firstName;
        }
      });
  }

  submitHandler() {
    if (this.accountSetupForm.invalid) {
      this.accountSetupForm.markAllAsTouched();
      return;
    }
    const values = this.accountSetupForm.value;
    if (typeof values.investmentStyles === 'string') {
      values.investmentStyles = [];
    }
    if (typeof values.rehabComfortabilities === 'string') {
      values.rehabComfortabilities = [];
    }
    // transform each entity into a json
    values.entities = values.entities.map(
      (entity: { id?: string; full_name: string }) => JSON.stringify(entity)
    );

    this.store.dispatch(
      setupAccountAction({
        input: {
          roles: values.roles,
          submarketIds: values.submarketIds,
          investmentStyles: values.investmentStyles,
          rehabComfortabilities: values.rehabComfortabilities,
          entities: values.entities,
        },
      })
    );
  }

  handleRoles(values: string[]) {
    this.accountSetupForm.controls['roles'].setValue(values);
  }

  handleMarkets(values: string[]) {
    this.accountSetupForm.controls['markets'].setValue(values);
  }

  handleInvestmentStyles(values: string[]) {
    this.accountSetupForm.controls['investmentStyles'].setValue(values);
  }

  handleRehabComfortabilities(values: string[]) {
    this.accountSetupForm.controls['rehabComfortabilities'].setValue(values);
  }

  handleEntities(values: { key: string; value: string }[]) {
    const entities = values.map((entity) =>
      entity.key !== '-'
        ? {
            id: entity.key,
            full_name: entity.value,
          }
        : {
            full_name: entity.value,
          }
    );
    this.accountSetupForm.controls['entities'].setValue(entities);
  }

  handleSubmarketIds(values: string[]) {
    this.accountSetupForm.controls['submarketIds'].setValue(values);
  }
}
