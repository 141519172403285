<div
  class="wrapper"
  [class.theme-dark]="theme === 'dark'"
  [class.error]="errors && touched"
  [class.with-prefix]="prefix"
  [class.with-label]="label"
  [class.selector]="selector"
  [class.disabled]="disabled"
>
  <label
    *ngIf="label"
    [for]="for"
    class="global-input-label"
    [ngClass]="{
      active: focused || !!value || value === 0 || !!prefix,
      'empty-selector': !!selector && !value
    }"
    >{{ label }}</label
  >

  <span *ngIf="prefix" class="prefix" [ngClass]="{ 'with-label': label }">{{
    prefix
  }}</span>

  <ng-content></ng-content>

  <div *ngIf="errors && errorMessage && touched" class="input-error-message">
    {{ errorMessage }}
  </div>

  <app-shared-icon
    *ngIf="iconName"
    [name]="iconName"
    [size]="iconSize"
    class="toggle-visibility"
    (click)="hangleToggleVisibility()"
  ></app-shared-icon>
</div>
