<div
  *ngIf="open"
  class="wrapper-base-modal"
  [ngClass]="{ 'size-small': size === 'small' }"
>
  <div class="container" [ngClass]="{ 'is-mobile': isMobile }">
    <app-shared-icon
      name="close"
      class="close-icon"
      [size]="14"
      (click)="onCloseHandler()"
    ></app-shared-icon>

    <header *ngIf="!!name">
      <h4>
        {{ name }} <sup *ngIf="sup">{{ sup }}</sup>
      </h4>
    </header>

    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
