<form [formGroup]="accountSetupForm" (ngSubmit)="submitHandler()">
  <div *ngIf="loadingSubmarkets || skipStep1 === undefined" class="mb-10">
    <app-shared-loading position="left"></app-shared-loading>
  </div>

  <ng-container *ngIf="!loadingSubmarkets && skipStep1 !== undefined">
    <div [ngClass]="{ hidden: step !== 1 || !!skipStep1 }">
      <app-account-setup-modal-form-investor2
        (selectedChange)="handleEntities($event)"
        [error]="accountSetupForm.get('entities')?.value.length === 0"
      ></app-account-setup-modal-form-investor2>
    </div>

    <div [ngClass]="{ hidden: step !== 2 }">
      <app-shared-multi-selector
        name="roles"
        label="My roles"
        [focused]="false"
        [disabled]="loading"
        [options]="options['roles']"
        [selected]="[]"
        (handleChange)="handleRoles($event)"
        [errors]="accountSetupForm.get('roles')?.errors"
        [touched]="accountSetupForm.get('roles')?.touched"
      ></app-shared-multi-selector>

      <!-- <app-shared-multi-selector
        name="markets"
        label="Interested markets"
        [disabled]="loading"
        [options]="options['markets']"
        [selected]="[]"
        (handleChange)="handleMarkets($event)"
        [errors]="accountSetupForm.get('markets')?.errors"
        [touched]="accountSetupForm.get('markets')?.touched"
      ></app-shared-multi-selector> -->

      <app-shared-multi-selector
        name="submarketIds"
        label="Markets"
        [disabled]="loading"
        [options]="options['submarkets']"
        [selected]="[]"
        (handleChange)="handleSubmarketIds($event)"
        [errors]="accountSetupForm.get('submarketIds')?.errors"
        [touched]="accountSetupForm.get('submarketIds')?.touched"
        [searchable]="true"
      ></app-shared-multi-selector>

      <app-shared-multi-selector
        name="investmentStyles"
        label="My investment style"
        [disabled]="loading"
        [options]="options['investmentStyles']"
        [selected]="[]"
        (handleChange)="handleInvestmentStyles($event)"
        [errors]="accountSetupForm.get('investmentStyles')?.errors"
        [touched]="accountSetupForm.get('investmentStyles')?.touched"
      ></app-shared-multi-selector>

      <app-shared-multi-selector
        name="rehabComfortabilities"
        label="Interest in rehab projects"
        [disabled]="loading"
        [options]="options['rehabComfortabilities']"
        [selected]="[]"
        (handleChange)="handleRehabComfortabilities($event)"
        [errors]="accountSetupForm.get('rehabComfortabilities')?.errors"
        [touched]="accountSetupForm.get('rehabComfortabilities')?.touched"
      ></app-shared-multi-selector>
    </div>

    <app-shared-button
      *ngIf="step === 1"
      [loading]="loading"
      [disabled]="
        loading || accountSetupForm.get('entities')?.value.length === 0
      "
      (click)="step = 2"
      >Next</app-shared-button
    >

    <div *ngIf="step === 2" class="flex gap-4 items-center">
      <div class="flex-1" *ngIf="!skipStep1">
        <app-shared-button
          [loading]="loading"
          (click)="step = 1"
          theme="outline"
        >
          Go back
        </app-shared-button>
      </div>

      <div class="flex-1">
        <app-shared-button
          [loading]="loading"
          [disabled]="
            loading || accountSetupForm.get('entities')?.value.length === 0
          "
          (click)="submitHandler()"
          >Complete</app-shared-button
        >
      </div>
    </div>
  </ng-container>

  <div class="mt-7 text-sm text-center">
    You're logged in as {{ userFirstName }}. Not you?
    <a href="/logout">Log out</a>.
  </div>
</form>
