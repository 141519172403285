import { createSelector } from '@ngrx/store';

import { AppState } from '../app.state';
import { AuthState } from './auth.reducer';

export const selectAuth = (state: AppState) => state.auth;

export const selectAuthLoading = createSelector(
  selectAuth,
  (state: AuthState) => state.loading
);

export const selectAuthErrors = createSelector(
  selectAuth,
  (state: AuthState) => state.errors
);

export const selectAuthToken = createSelector(
  selectAuth,
  (state: AuthState) => state.token
);

export const selectAuthUser = createSelector(
  selectAuth,
  (state: AuthState) => state.user
);

export const selectAuthUserEntities = createSelector(
  selectAuth,
  (state: AuthState) => state.userEntities
);

export const selectAuthRequestPasswordReset = createSelector(
  selectAuth,
  (state: AuthState) => state.requestPasswordReset
);

export const selectAuthResetPassword = createSelector(
  selectAuth,
  (state: AuthState) => state.resetPassword
);

export const selectLastSMSSent = createSelector(
  selectAuth,
  (state: AuthState) => state.lastSMSSent
);

export const selectUpdatePhoneNumberSuccess = createSelector(
  selectAuth,
  (state: AuthState) => state.updatePhoneNumberSuccess
);

export const selectReferrerCode = createSelector(
  selectAuth,
  (state: AuthState) => state.referrerCode
);

export const selectEntityId = createSelector(
  selectAuth,
  (state: AuthState) => state.entityId
);

export const selectUpdateProfile = createSelector(
  selectAuth,
  (state: AuthState) => state.updateProfile
);

export const selectAuthModal = createSelector(
  selectAuth,
  (state: AuthState) => state.authModal
);
